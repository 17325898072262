<template>
  <v-row no-gutters>
    <v-col cols="12" :lg="colOption.colsDropdown" :sm="colOption.colsDropdown">
      <v-select
        current
        v-model="lang"
        :items="langs"
        :label="$t('Language')"
        hide-details="auto"
      ></v-select>
    </v-col>
    <v-col cols="12" :lg="colOption.colsText" :sm="colOption.colsText">
      <v-text-field
        v-if="!itemData.TranslatedTitle"
        :label="$t('Object title')"
        v-model="itemData.Title"
        :class="[colOption.colsText != 12 ? 'ml-0 ml-lg-4 ml-sm-4' : '']"
      ></v-text-field>

      <v-text-field
        v-else
        :label="$t('Object title')"
        v-model="itemData.TranslatedTitle[lang]"
        :class="[colOption.colsText != 12 ? 'ml-0 ml-lg-4 ml-sm-4' : '']"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>

export default {
  props: ["itemData", "colOption"],
  data() {
    return {
      langs: this.$config.languages,
      lang: "Nl",
    };
  },
  created() {
    if (!this.itemData.TranslatedTitle) {
      this.itemData["TranslatedTitle"] = {};
      this.itemData["TranslatedTitle"][this.lang] = this.itemData.Title;
    }
  },

  watch: {
    lang: function (param) {
      this.$emit("changeLanguage", param);
    },
    "itemData.TranslatedTitle": function (param) {
      this.$emit("changeTranslatedTitle", param);
    },
  },
};
</script>

<style>
</style>